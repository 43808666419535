
import { Component, Vue } from 'vue-property-decorator';
import MainHeader from '@/components/header/MainHeader.vue';
import googleClient from '../../../../google_client.json';
import facebookClient from '../../../../facebook_client.json';
import { readIsLoggedIn, readLoginError } from '@/store/main/getters';
import { dispatchLogIn } from '@/store/main/actions';
import { getLocalSessionId } from '@/utils';

@Component({
    components: {
        MainHeader,
    },
})

export default class LogIn extends Vue {
    public username = '';
    public password = '';
    public passwordInputType = 'password';
    public usernameType = 'email';
    public formError: boolean = false;
    public loadingState = {
        login_btn: false,
    };


    get loggedIn() {
        return readIsLoggedIn(this.$store);
    }
    get loginError() {
        return readLoginError(this.$store);
    }
    public async submit() {
        this.formError = false;
        if (this.usernameType === 'email') {
            if (!this.username || (this.username && !/(.+)@(.+){1,}\.(.+){2,}/.test(this.username))) {
                this.formError = true;
            }
        }
        if (this.formError) {
            return;
        }
        this.loadingState.login_btn = true;
        await dispatchLogIn(
            this.$store,
            {
                username: this.username, password: this.password,
                anon_session_id: getLocalSessionId(),
            },
        );
        if (this.loginError) {
            this.$toast.error(this.$t('Неверные учетные данные').toString());
        }
        this.loadingState.login_btn = false;
    }
    public handleAuthByGoogle() {
        // this.$toast.error(this.$t('Пока недоступно').toString());
        const clientId = googleClient.web.client_id;
        const redirectUri = googleClient.web.redirect_uris[0];
        window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=profile email&include_granted_scopes=true`;
    }
    public handleAuthByFacebook() {
        // this.$toast.error(this.$t('Пока недоступно').toString());
        const clientId = facebookClient.web.client_id;
        const redirectUri = facebookClient.web.redirect_uri;
        window.location.href = `https://www.facebook.com/v14.0/dialog/oauth?client_id=${clientId}&redirect_uri=${redirectUri}&state=test&scope=public_profile email&response_type=token`;
    }
    public handleAuthByApple() {
        this.$toast.error(this.$t('Пока недоступно').toString());
    }
}
